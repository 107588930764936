import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useConfig } from './useAuth';

export default function useCheckAccess() {
  const location = useLocation();
  const { user } = queryString.parse(location.search);
  const config = useConfig();

  return user && user == config.configuration.service_access_token
    ? true
    : false;
}

import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import SearchResults from './SearchResults';
import useSearch from '../../hooks/useSearch';
import { useDebounce } from '../../hooks/useDebounce';
import { useListFocus } from '../../hooks/useListFocus';
import { useCloseClick } from '../../hooks/useCloseClick';
import { useTranslation } from '../../hooks/useTranslation';
import useCheckAccess from '../../hooks/useCheckAccess';
import { useParams } from 'react-router-dom';

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const SearchIconWrapper = styled.button<{ inHeader: boolean | undefined }>`
  background: ${({ theme }) => theme.colors.esabYellow};
  position: absolute;
  right: 0;
  cursor: pointer;
  height: 100%;
  padding: ${({ inHeader }) => (inHeader ? '0 13px' : '0 19px')};
  border-top-right-radius: ${({ theme, inHeader }) =>
    inHeader && theme.radii.m};
  border-bottom-right-radius: ${({ theme, inHeader }) =>
    inHeader && theme.radii.m};

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    padding: 0 12px;
  }
`;

const ClearIconWrapper = styled.button<{ inHeader: boolean | undefined }>`
  display: flex;
  animation: ${show} 0.8s;
  position: absolute;
  right: ${({ inHeader }) => (inHeader ? '63px' : '80px')};
  cursor: pointer;
  height: 100%;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    right: 50px;
  }
`;

const SearchIcon = styled.img<{ inHeader: boolean | undefined }>`
  width: ${({ inHeader }) => (inHeader ? ' 25px' : ' 27px')};
  height: ${({ inHeader }) => (inHeader ? ' 25px' : ' 27px')};

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    width: 17px;
    height: 17px;
  }
`;

const ClearIcon = styled.img`
  width: 13.5px;
  height: 13.5px;
`;

const Wrapper = styled.div<{ inHeader: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  position: relative;
  border-style: solid;
  border-width: ${({ theme, inHeader }) =>
    inHeader ? theme.borderWidths.s : theme.borderWidths.xl}px;
  border-color: ${({ theme, inHeader }) =>
    inHeader ? theme.colors.esabBlack80 : 'rgba(0, 0, 0, 0.25)'};
  border-radius: ${({ theme, inHeader }) => inHeader && theme.radii.m};
  width: ${({ inHeader }) => inHeader && '360px'};
  animation: ${show} 0.8s;
`;

const SearchInput = styled.input<{
  active: boolean;
  inHeader: boolean | undefined;
}>`
  padding: ${({ inHeader }) => (inHeader ? '12px 20px' : '15px 20px')};
  font-size: ${({ inHeader }) => (inHeader ? '17px' : '18px')};

  line-height: 20.7px;
  color: ${({ theme }) => theme.colors.esabBlack};
  font-weight: 700;
  outline: none;
  border-radius: ${({ theme, inHeader }) => inHeader && theme.radii.m};

  &::placeholder {
    color: ${({ theme }) => theme.colors.esabBlack20};
  }

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    font-size: ${({ theme }) => theme.fontSizes.xxs};
    line-height: 13.13px;
    padding: 9px 13px;
  }
`;

type SearchBoxProps = {
  inHeader?: boolean;
};

const SearchBox: React.FC<SearchBoxProps> = ({ inHeader }) => {
	const manifestId = useParams<{ manifestId: string }>().manifestId;
  const { t } = useTranslation();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState<string>('');
  const searchFilter = useCheckAccess()
    ? `mapfile ne null and manifestId eq '${manifestId}'`
    : `mapfile ne 'Service' and mapfile ne null and manifestId eq '${manifestId}'`;
  const debouncedValue = useDebounce<string>(value, 300);
  const { isError, data } = useSearch(debouncedValue, searchFilter);
  const [currentFocus, setCurrentFocus] = useListFocus(
    data != undefined ? data.count : 0,
    wrapperRef
  );
  const [showResultsDropdown, setShowResultsDropdown] =
    React.useState<boolean>(false);
  const [active, setActive] = React.useState(false);
  const close = useCloseClick(wrapperRef, debouncedValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleKeypress = () => {
    setActive(true);
  };

  React.useEffect(() => {
    if (debouncedValue.length > 2) {
      setShowResultsDropdown(true);
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    if (currentFocus === -1) {
      setActive(true);
      inputRef.current?.focus();
    }
  }, [currentFocus]);

  React.useEffect(() => {
    if (debouncedValue.length > 2) {
      setShowResultsDropdown(true);
    }
    if (close) {
      setShowResultsDropdown(false);
    }
  }, [debouncedValue, close]);

  if (!isError) {
    // Handle error
  }

  return (
    <Wrapper ref={wrapperRef} inHeader={inHeader}>
      <SearchInput
        type="text"
        placeholder={t('searchbox.watermark')}
        value={value}
        active={active}
        onChange={handleChange}
        onKeyDown={handleKeypress}
        onBlur={() => {
          setActive(false);
        }}
        onClick={() => {
          setActive(true);
        }}
        ref={inputRef}
        inHeader={inHeader}
      />
      {debouncedValue.length > 0 && (
        <ClearIconWrapper
          onMouseDown={() => {
            setValue('');
            setShowResultsDropdown(false);
          }}
          inHeader={inHeader}
        >
          <ClearIcon src="/images/clear-icon.svg" />
        </ClearIconWrapper>
      )}
      <SearchIconWrapper inHeader={inHeader}>
        <SearchIcon src="/images/search-icon.svg" inHeader={inHeader} />
      </SearchIconWrapper>
      <SearchResults
        data={data != undefined ? data.value : []}
        showResultsDropdown={showResultsDropdown}
        currentFocus={currentFocus}
        setCurrentFocus={setCurrentFocus}
        inHeader={inHeader}
      />
    </Wrapper>
  );
};

export default SearchBox;

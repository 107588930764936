import axios from 'axios';
import { constants } from '../constants'
const api = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL
});

const searchApi = axios.create({});

// searchApi.interceptors.request.use(
//   (response) => {
//     const token = getToken(constants.tokenVersion);

//     if (token) {
//       response.headers.Authorization = `Bearer ${token}`;
//     }

//     return response;
//   },
//   (err) => Promise.reject(err)
// );

api.interceptors.request.use(
  (response) => {
    return response;
  },
  (err) => Promise.reject(err)
);

function setToken(key: string, token: string): void {
  const currentToken = getToken(key);

  if (currentToken !== token) {
    sessionStorage.setItem(key, token);
  }
}

function getToken(key: string): string | null {
  return sessionStorage.getItem(key);
}

export default api;
export { setToken, getToken, searchApi };

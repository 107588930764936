const theme = {
  colors: {
    esabYellow: '#FBE400',
    esabBlack: '#1A171B',
    esabBlack10: '#E8E8E8',
    esabBlack20: '#D1D1D1',
    esabBlack30: '#BAB9BB',
    esabBlack44: '#9A999B',
    esabBlack80: '#484549',
    esabCoolGrey: '#888B8D',
    esabLightBlueGray: '#B7C9D3',
    lightGrey: '#F7F7F7',
    esabTrueBlack: '#000',
    esabBlack60: '#767476',
    esabHoverLinks: '#6D6E71',
    infoTypes: {
      service: '#78C2DE',
      instruction: '#FBE400',
      parts: '#B9D3B7',
    },
  },

  space: {
    xxl: '60px',
    xl: '40px',
    l: '32px',
    m: '20px',
    s: '16px',
    xs: '8px',
    xxs: '4px',
    xxxs: '2px',
    gutter: '20px',
    attention: '21px',
    table: '5px',
  },
  fontSizes: {
    xxxl: '50px',
    xxl: '36px',
    xl: '26px',
    l: '20px',
    m: '16px',
    s: '14px',
    xxs: '12px',
    xxxs: '10px',
  },
  fontWeights: {
	bold: '700',
	regular: '400',
  },
  lineHeights: {
    l: '42px',
    m: '30px',
    s: '24px',
  },
  fonts: {
    regular: 'Arial, sans-serif',
  },
  radii: {
    l: '10px',
    m: '6px',
    s: '3.85px',
  },
  borderWidths: {
    xl: 6.42,
    l: 4,
    m: 2,
    s: 1,
    attention: 3,
  },
  sizes: {
    containerWide: '1220px',
  },
  screens: {
    md: '768px',
    sm: '640px',
  },
};

export type ThemeType = typeof theme;

export default theme;

import * as React from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  children: React.ReactChild;
  id: string;
};

export default function Portal({ children, id }: PortalProps) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    ref.current = document.querySelector(id);
    setMounted(true);
  }, [id]);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
}

import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { ReactComponent as HotspotLinkIcon } from '../icons/hotspotLink.svg';

const HotspotWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const HotspotPoint = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fbe400;
  cursor: pointer;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  color: #1a171b;
  transition: background-color 0.3s ease, color 0.3s ease;
  z-index: 10;

  &:hover {
    background-color: #1a171b;
    color: #fbe400;
  }
`;

const InteractiveArea = styled.div`
  position: absolute;
  padding: 20px;
  z-index: 1000;
`;

const PopoverContent = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: max-content;
  max-width: 300px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #fbe400;

  .title {
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 10px;
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
`;

const LearnMoreWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const LearnMoreLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 38px;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  font-weight: 400;
  text-decoration: none;
  background-color: #ffffff;
  color: #1a171b;
  font-size: 16px;
  transition: background-color 0.3s ease;
  box-shadow: 0px 1px 3px 0px #00000040;
  margin-top: 10px;
`;

const LinkIcon = styled(HotspotLinkIcon)`
  width: 15px;
  height: 15px;
  margin-right: 5px;

  * {
    stroke: #1a171b;
    fill: none;
  }
`;

const Hotspots = ({
  children,
  areas,
  hotspotSections,
  domToReact,
  options,
}) => {
  const [activeHotspot, setActiveHotspot] = useState(null);
  const timeoutRef = useRef(null);

  const hotspots = areas.map((area, index) => ({
    id: area.attribs.href,
    x: parseFloat(area.attribs.x),
    y: parseFloat(area.attribs.y),
    content: hotspotSections[index],
    number: index + 1,
  }));

  const handleMouseEnter = useCallback((index) => {
    clearTimeout(timeoutRef.current);
    setActiveHotspot(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setActiveHotspot(null);
    }, 100);
  }, []);

  const contentReplace = (node) => {
    if (node.attribs && node.attribs.class === 'title') {
      return <div className="title">{domToReact(node.children, options)}</div>;
    }
    if (node.name === 'a' && node.attribs.class === 'url') {
      return (
        <LearnMoreWrapper>
          <LearnMoreLink
            href={node.attribs.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon />
            {domToReact(node.children, options)}
          </LearnMoreLink>
        </LearnMoreWrapper>
      );
    }
  };

  return (
    <HotspotWrapper>
      {children}
      {hotspots.map((hotspot, index) => (
        <InteractiveArea
          key={index}
          style={{
            left: `calc(${hotspot.x * 100}% - 20px)`,
            top: `calc(${hotspot.y * 100}% - 20px)`,
          }}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          {activeHotspot === index && (
            <PopoverContent>
              <ContentWrapper>
                {domToReact([hotspot.content], {
                  ...options,
                  replace: contentReplace,
                })}
              </ContentWrapper>
            </PopoverContent>
          )}
          <HotspotPoint>{hotspot.number}</HotspotPoint>
        </InteractiveArea>
      ))}
    </HotspotWrapper>
  );
};

export default Hotspots;

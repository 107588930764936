import * as React from 'react';
import { useMutation } from 'react-query';
import api, { getToken, setToken } from '../services/api';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { constants } from '../constants'

type AuthRequestData = {
  username: string;
  password: string;
  customer: string;
  site: string;
  environment: string;
};

export function useSetConfig(): any {
  return useMutation<Esab.ConfigObject, any>(() =>
    api
      .get(
        `/auth/v1/jwt/config`,
        {
          headers: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_KEY,
            Authorization: `Bearer ${getToken(constants.tokenVersion)}`,
          }
        },
      )
      .then((response) => response.data)
  );
}

export function useConfig(): Esab.ConfigObject | undefined {
  if (sessionStorage.getItem(constants.configVersion)) {
    return JSON.parse(sessionStorage.getItem(constants.configVersion));
  }

  return undefined;
}

export function useAuth(): boolean {
  const [token, setJwtToken] = React.useState(getToken(constants.tokenVersion));
  const [authenticated, setAuthenticated] = React.useState(
    getToken(constants.tokenVersion) ? true : false
  );

  React.useEffect(() => {
    const auth = async () => {
      const jwt = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/auth/v1/jwt/authenticate` ?? '', {
        username: process.env.REACT_APP_USERNAME,
        password: process.env.REACT_APP_PASSWORD,
        customer: process.env.REACT_APP_CUSTOMER,
        site: process.env.REACT_APP_SITE,
        environment: process.env.REACT_APP_ENVIRONMENT
      },
        {
          headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY
          }
        }
      );

      setToken(constants.tokenVersion, jwt.data.token)
      setJwtToken(jwt.data.token);
      setAuthenticated(true);
    };

    if (!authenticated) {
      auth();
    } else {
      const decodedToken = jwt_decode<Esab.JwtToken>(token);
      if ((new Date().getTime() / 1000) >= decodedToken.exp) {
        auth();
      }
    }

  }, [token]);

  return authenticated;
}
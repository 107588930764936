import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: ${({ theme }) => theme.sizes.containerWide};
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding: 0 26px;
  }
`;

const PageContentWrapper: React.FC = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default PageContentWrapper;

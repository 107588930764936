import * as React from 'react';
import styled from 'styled-components';
import SearchBox from './search/SearchBox';
import { useCloseClick } from '../hooks/useCloseClick';
import { useLocation } from 'react-router-dom';
import QuestionmarkModal from './modals/QuestionmarkModal';
import { useTranslation } from '../hooks/useTranslation';
import { useInternalLink } from '../hooks/useInternalLink';
import useCheckAccess from '../hooks/useCheckAccess';

const Logo = styled.img`
  width: 64px;
  max-width: initial;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    margin-bottom: 5px;
  }
`;

const Icon = styled.img<{ hideSearch: boolean }>`
  width: 25px;
  height: 25px;
  margin-left: ${({ hideSearch }) => (hideSearch ? '25px' : '20px')};
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    &:nth-child(1) {
      margin-left: 0;
      margin-right: 5px;
    }
    &:nth-child(2) {
      margin-left: 5px;
    }
  }
`;

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.esabYellow};
  padding: 22px 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const WrapperContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.sizes.containerWide};
  width: 100%;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.sizes.containerWide}) {
    padding: 0 26px;
  }

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    flex-direction: column;
  }
`;

const SearchBoxWrapper = styled.div`
  position: absolute;
  right: 0;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    right: -150px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    flex-direction: column;
  }
`;

const Item = styled.li`
  text-transform: uppercase;
  padding: 0 22px;
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: 23px;
  font-weight: 700;

  &:first-child {
    padding-left: 0;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    padding: 0 12px;
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: 17px;
  }

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    padding: 5px 0px;
  }
`;

const IconsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    margin-top: 10px;
  }
`;

const A = styled.a<{ active: boolean }>`
  border-bottom-width: ${({ active }) => active && '2px'};
  border-bottom-color: ${({ theme }) => theme.colors.esabBlack80};
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.colors.esabHoverLinks};
    border-bottom-color: ${({ theme }) => theme.colors.esabHoverLinks};
  }
`;

type HeaderProps = {
  hideSearch?: boolean;
};

const Header: React.FC<HeaderProps> = ({ hideSearch }) => {
  const { internalLink } = useInternalLink();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const IsActive = (url: string) => {
    return pathname.split('/').length > 2
      ? pathname.split('/')[2].indexOf(url) > -1
      : false;
  };
  const hasAccess = useCheckAccess();

  const [showSearchBox, setShowSearchBox] = React.useState(false);
  const searchBoxRef = React.useRef<HTMLDivElement>(null);
  const toogleSearchBoxVisibility = React.useCallback(() => {
    setShowSearchBox(!showSearchBox);
  }, [showSearchBox]);
  const close = useCloseClick(searchBoxRef, showSearchBox);
  const [showModal, setShowModal] = React.useState(false);
  const clickHandler = React.useCallback((show: boolean): void => {
    setShowModal(show);
  }, []);

  React.useEffect(() => {
    if (close) {
      toogleSearchBoxVisibility();
    }
  }, [close, toogleSearchBoxVisibility]);

  const items: { label: string; url: string }[] = [
    {
      label: t('menu.item.instruction'),
      url: `${internalLink('/instruction')}`,
    },
    {
      label: t('menu.item.partsequipment'),
      url: `${internalLink('/parts')}`,
    },
    { label: t('menu.item.downloads'), url: `${internalLink('/downloads')}` },
  ];
  hasAccess
    ? items.splice(1, 0, {
        label: t('menu.item.service'),
        url: `${internalLink('/service')}`,
      })
    : null;
  return (
    <>
      <Wrapper>
        <WrapperContent>
          <MenuWrapper>
            <Menu>
              <Item>
                <a href={`${internalLink('/')}`}>
                  <Logo src="/images/logo.svg" alt="Esab" />
                </a>
              </Item>
              {items.map((item, index) => (
                <Item key={index}>
                  <A href={item.url} active={IsActive(item.url.split('/')[2])}>
                    {item.label}
                  </A>
                </Item>
              ))}
            </Menu>
          </MenuWrapper>
          <IconsWrapper>
            <Icon
              src="/images/help-icon.svg"
              onClick={() => clickHandler(true)}
              hideSearch={hideSearch}
            />
            <Icon
              src="/images/search-icon.svg"
              onClick={toogleSearchBoxVisibility}
              hideSearch={hideSearch}
              style={{ display: hideSearch ? 'none' : 'initial' }}
            />
            {showSearchBox && (
              <SearchBoxWrapper ref={searchBoxRef}>
                <SearchBox inHeader={true} />
              </SearchBoxWrapper>
            )}
          </IconsWrapper>
        </WrapperContent>
      </Wrapper>
      <QuestionmarkModal
        isVisible={showModal}
        closeHandler={() => clickHandler(false)}
      />
    </>
  );
};

export default Header;

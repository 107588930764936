import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const GridTableStyle = css`
  border-width: ${({ theme }) => theme.borderWidths.s}px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.esabBlack44};

  th {
    border-width: ${({ theme }) => theme.borderWidths.s}px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.esabBlack44};
  }

  td {
    border-width: ${({ theme }) => theme.borderWidths.s}px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.esabBlack44};
  }
`;

const StandardTableStyles = css`
  td {
    border-bottom-width: ${({ theme }) => theme.borderWidths.s}px;
    border-bottom-style: solid;
    border-color: ${({ theme }) => theme.colors.esabBlack44};
  }
`;

const TableWrapper = styled.table<{ grid: boolean; standard: boolean }>`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: ${({ theme }) => theme.space.s};
  text-align: left;

  th {
    background-color: ${({ theme }) => theme.colors.esabBlack10};
    padding: ${({ theme }) => theme.space.table};
  }

  td {
    padding: ${({ theme }) => theme.space.table};
    line-height: ${({ theme }) => theme.lineHeights.s};
  }

  ${({ grid }) => grid && GridTableStyle};
  ${({ standard }) => standard && StandardTableStyles};
`;

type TableProps = {
  node: Esab.Topic;
  children: React.ReactNode;
};

const Table: FC<TableProps> = ({ node, children }) => {
  const { attribs } = node;

  return (
    <>
      <TableWrapper
        className={attribs.class}
        grid={attribs.class === 'grid-table'}
        standard={attribs.class === 'standard-table'}
        id={attribs.id}
      >
        {children}
      </TableWrapper>
    </>
  );
};

export default Table;

export const types: any[] = [
  { key: '', value: 'All' },
  { key: 'ACC', value: 'Accessories' },
  { key: 'AI', value: 'Assembly instruction' },
  { key: 'DOC', value: 'Declaration of Conformity' },
  { key: 'INT', value: 'Integrator manual' },
  { key: 'ILM', value: 'Installation manual' },
  { key: 'IM', value: 'Instruction manual' },
  { key: 'HB', value: 'Handbook' },
  { key: 'FT', value: 'Fault tracing guide' },
  { key: 'PM', value: 'Programming manual' },
  { key: 'QG', value: 'Quick guide' },
  { key: 'QSG', value: 'Quick start guide' },
  { key: 'SI', value: 'Safety instruction' },
  { key: 'SM', value: 'Service manual' },
  { key: 'SU', value: 'Software upgrade instruction' },
  { key: 'SPL', value: 'Spare parts list (Replacement parts)' },
  { key: 'TI', value: 'Troubleshooting instruction' },
  { key: 'WD', value: 'Wiring diagram' },
  { key: 'WP', value: 'Wear parts' },
];

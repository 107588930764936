import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const baseListStyling = css`
  li {
    padding: ${({ theme }) => theme.space.xs} 0;
    font-size: ${({ theme }) => theme.fontSizes.m};
    font-weight: 400;
    line-height: ${({ theme }) => theme.lineHeights.m};
  }
`;

const LegendStyle = css`
  padding-left: ${({ theme }) => theme.space.l};
  counter-reset: item;

  > li {
    position: relative;
    list-style: none;

    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-size: ${({ theme }) => theme.fontSizes.s};
      width: 22px;
      height: 22px;
      border-radius: 22px;
      position: absolute;
      left: -33px;
    }
  }
`;

const AlphaLegendStyles = css`
  > li {
    &::before {
      counter-increment: item;
      content: counter(item, upper-latin) '';
    }
  }
`;

const NumericLegendStyles = css`
  counter-reset: count;

  > li {
    &::before {
      counter-increment: count;
      content: counter(count) '';
    }
  }
`;

const StepsStyle = css`
  list-style: decimal;

  li {
    &::marker {
    }
  }
`;

const Ul = styled.ul`
  ${baseListStyling}
  padding-left: ${({ theme }) => theme.space.l};

  li {
    list-style: disc;

    &::marker {
    }
  }
`;

type OlTypes = {
  alphaLegend: boolean;
  numericLegend: boolean;
  steps: boolean;
};

const Ol = styled.ol<OlTypes>`
  ${baseListStyling}
  padding-left: ${({ theme }) => theme.space.m};

  list-style: decimal;

  li {
    padding-left: ${({ theme }) => theme.space.xs};
  }

  ${({ alphaLegend, numericLegend }) =>
    (alphaLegend || numericLegend) && LegendStyle};
  ${({ alphaLegend }) => alphaLegend && AlphaLegendStyles};
  ${({ numericLegend }) => numericLegend && NumericLegendStyles};
  ${({ steps }) => steps && StepsStyle};

  ul {
    li {
      list-style: disc;
    }
  }
`;

type ListProps = {
  node: Esab.Topic;
  children: React.ReactNode;
};

const List: FC<ListProps> = ({ node, children }) => {
  const { name, attribs } = node;

  switch (name) {
    case 'ul':
      return <Ul className={attribs.class}>{children}</Ul>;

    case 'ol': {
      return (
        <Ol
          className={attribs.class}
          alphaLegend={attribs.class === 'alpha-legend'}
          numericLegend={attribs.class === 'numeric-legend'}
          steps={attribs.class === 'steps'}
        >
          {children}
        </Ol>
      );
    }

    default:
      return <ul>{children}</ul>;
  }
};

export default List;

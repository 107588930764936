import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import styled from 'styled-components';
import { PageContentRow } from '../components/layout/PageContentRow';
import Breadcrumbs from '../components/Breadcrumbs';
import H2 from '../components/typography/H2';
import FeedBack from '../components/FeedBack';
import { useTranslation } from '../hooks/useTranslation';
import TopicContent from 'src/components/TopicContent';
import Divider from 'src/components/layout/Divider';
import { useNodes } from 'src/hooks/useNodes';
import { useFetchNode } from 'src/hooks/useFetchData';

const MainContent = styled.div`
  max-width: 860px;
  flex: 1;
  padding-right: 10px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: ${({ theme }) => theme.space.l};
  }
`;

const StyledH2 = styled(H2)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  text-transform: uppercase;
  line-height: 24px;
`;

const SideBar = styled.div`
  flex: 1;
  max-width: 200px;
  padding-left: 10px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    max-width: 100%;
    padding-left: 0;
  }
`;

const Topic = () => {
  const { t } = useTranslation();
  const { topicId, path } = useParams<{ topicId: string; path: string }>();
  const { data } = useFetchNode(
    `${path.charAt(0).toUpperCase() + path.slice(1)}.json `
  );

  const { breadcrumbs } = useNodes(data, topicId, path);

  return (
    <Page>
      <PageContentWrapper>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <PageContentRow>
          <MainContent>
            <TopicContent topicId={topicId} />
          </MainContent>
          <SideBar>
            <StyledH2>{t('feedback.title')}</StyledH2>
            <Divider />
            <FeedBack />
          </SideBar>
        </PageContentRow>
      </PageContentWrapper>
    </Page>
  );
};

export default Topic;

import * as React from 'react';
import { useQuery } from 'react-query';
import { getToken, searchApi } from '../services/api';
import { useParams } from 'react-router-dom';
import useCheckAccess from './useCheckAccess';
import { constants } from '../constants';

export default function useSearch(term: string, filter: string) {

  return useQuery<Esab.SearchResults>(
    ['search', term],
    () =>
      searchApi
        .get(
          `${process.env.REACT_APP_AZURE_SEARCH_URL}/indexes/${process.env.REACT_APP_CONTENT_INDEX}/docs?api-version=2021-04-30-Preview&search=${term}&$filter=${filter}&$count=true&api-key=${process.env.REACT_APP_AZURE_API_KEY}`,
          {
            headers: {
				'Content-Type': 'application/json',
				'api-key': process.env.REACT_APP_AZURE_API_KEY
			  },
          }
        )
        .then((response) => response.data),
    {
      enabled: term.length > 2,
    }
  );
}

export function useProductSearch(term: string, filter: string) {
  return useQuery<Esab.ProductSearchResults>(['search', term], () =>
    searchApi
      .get(
        `${process.env.REACT_APP_AZURE_SEARCH_URL}/indexes/${process.env.REACT_APP_PRODUCT_INDEX}/docs?api-version=2021-04-30-Preview&search=${term}&$filter=${filter}&$count=true&api-key=${process.env.REACT_APP_AZURE_API_KEY}`,
        {
			headers: {
				'Content-Type': 'application/json',
				'api-key': process.env.REACT_APP_AZURE_API_KEY
			  },
        }
      )
      .then((response) => response.data)
  );
}

async function getManual(
  filter: string,
  url: string,
  index: string
): Promise<Esab.ProductSearchResults> {
  const manual = await searchApi.get(
    `${process.env.REACT_APP_AZURE_SEARCH_URL}/indexes/${index}/docs?api-version=2021-04-30-Preview&search=*&$filter=${filter}&orderby=date desc&$count=true&api-key=${process.env.REACT_APP_AZURE_API_KEY}`,
    {
		headers: {
			'Content-Type': 'application/json',
			'api-key': process.env.REACT_APP_AZURE_API_KEY
		  },
    }
  );
  return manual.data;
}

export function useManuals() {
  const initialState = {
    instructionManuals: {
      value: [],
      count: 0,
    },
    assemblyManuals: {
      value: [],
      count: 0,
    },
    downloadManuals: {
      value: [],
      count: 0,
    },
  };
  const { manifestId } = useParams<{ manifestId: string }>();
  const [manuals, setManuals] =
    React.useState<{
      instructionManuals: Esab.ProductSearchResults;
      assemblyManuals: Esab.ProductSearchResults;
      downloadManuals: Esab.ProductSearchResults;
    }>(initialState);
  const { data } = useProductSearch('*', `manifestId eq '${manifestId}'`);
  const hasAccess = useCheckAccess();

  const addUserFilter = () => {
    return `${hasAccess ? '' : `and accesstype/any(at: at eq 'public')`}`;
  };
  React.useEffect(() => {
    async function get() {
		const product = data.value[0];

      const instructionManuals = await getManual(
        `productid/any(p: search.in(p, '${product.productid}'))
		 and objecttype ne 'html'
         and manualtype/any(m: m eq 'IM') 
         and (pdflanguage/any(pdf: search.in(pdf, '${product.pdflanguage[0]}')) 
         or pdflanguage/any(pdf: search.in(pdf, 'en-GB'))) 
         and manualtitle ne '${product.manualtitle}' 
         ${addUserFilter()} `,
		 
		 process.env.REACT_APP_AZURE_SEARCH_URL,
			 process.env.REACT_APP_PRODUCT_INDEX
		 );
		 
		 const assemblyManuals = await getManual(
			 `productid/any(p: search.in(p, '${product.productid}'))
			 and manualtype/any(m: m eq 'AI') 
       and (pdflanguage/any(pdf: search.in(pdf, '${product.pdflanguage[0]}')) 
       or pdflanguage/any(pdf: search.in(pdf, 'en-GB'))) 
     and manualtitle ne '${product.manualtitle}' 
			 and objecttype ne 'html'
			 ${addUserFilter()}`,
			 process.env.REACT_APP_AZURE_SEARCH_URL,
			 process.env.REACT_APP_PRODUCT_INDEX
			 );

			// Changed search string - 2022/11/23
				// - Only include IMs that have a documentno that matches the HTML version
				// - If it isn't an IM manualtype but is still a download, relevant to this HTML version, include it

			 const downloadManuals = await getManual(
				 `productid/any(p: search.in(p, '${product.productid}')) 
         and (pdflanguage/any(pdf: search.in(pdf, '${product.pdflanguage[0]}')) 
         or pdflanguage/any(pdf: search.in(pdf, 'en-GB'))) 
				 and manualtitle eq '${product.manualtitle}' 
				 and (
					(manualtype/any(m: m eq 'IM')
					and documentno eq '${product.documentno}') 
					or 
					(manualtype/all(m: m ne 'IM')))
				 and objecttype ne 'html'
				 
        ${addUserFilter()}`,
        process.env.REACT_APP_AZURE_SEARCH_URL,
        process.env.REACT_APP_PRODUCT_INDEX
      );

      setManuals({
        instructionManuals,
        assemblyManuals,
        downloadManuals,
      });
    }
    if (data && data.value && data["@odata.count"]) {
      get();
    }
  }, [data]);

  return manuals;
}

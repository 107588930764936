function getCrumbs(child: Esab.Node, topicId: string, topLevel: string) {
	const title = typeof child.title !== "object" ? child.title?.replace(/ /g, '') : '';
  const breadcrumbs = {
    [title]: [
      {
        label: child.title,
        url: child.children
          ? `/${topLevel}/${child.topicId}`
          : `/${topLevel}/topics/${child.topicId}`,
      },
    ],
  };

  let currentNode: Esab.Node = child.topicId === topicId && child;

  function recurse(childNode: Esab.Node) {
    const children = childNode.children;

    for (const key in children) {
      if (children[key].topicId === topicId) {
        currentNode = children[key];
      }

      if (breadcrumbs[title] && children[key].topicId === topicId) {
        if (!breadcrumbs[title].find((b) => b.label === childNode.title)) {
          breadcrumbs[title].push({
            label: childNode.title,
            url: childNode.children
              ? `/${topLevel}/${childNode.topicId}`
              : `/${topLevel}/topics/${childNode.topicId}`,
          });
        }
        breadcrumbs[title].push({
          label: children[key].title,
          url: children[key].children
            ? `/${topLevel}/${children[key].topicId}`
            : `/${topLevel}/topics/${children[key].topicId}`,
        });
      }

      recurse(children[key]);
    }
  }

  if (child.children && child.topicId !== topicId) {
    recurse(child);
  }

  return { breadcrumbs: breadcrumbs[title], currentNode };
}

export function getChildren(
  data: Esab.Node,
  childId: string,
  topLevel?: string
): { nodeData: Esab.Node | undefined; breadcrumbs: Esab.BreadCrumb[] } {
  let node = {
    currentNode: undefined,
    breadcrumbs: [],
  };

	data.children.map((child) => {
    const { currentNode, breadcrumbs } = getCrumbs(child, childId, topLevel);
    if (currentNode) {
      node = { currentNode, breadcrumbs };
    }
  });

  return { nodeData: node.currentNode, breadcrumbs: node.breadcrumbs };
}

import * as React from 'react';
import Page from 'src/components/layout/Page';
import { PageContent } from 'src/components/layout/PageContent';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import Breadcrumbs from '../components/Breadcrumbs';
import H1 from '../components/typography/H1';
import { useTranslation } from '../hooks/useTranslation';
import styled from 'styled-components';

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: 32px;
  margin-bottom: 40px;
  max-width: 640px;
  width: 100%;
  margin-top: 25px;
`;

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <PageContentWrapper>
        <PageContent>
          <Breadcrumbs breadcrumbs={[]} />
          <H1>404</H1>
          <Text>{t('notFoundMessage')}</Text>
        </PageContent>
      </PageContentWrapper>
    </Page>
  );
};

export default NotFound;

import React from 'react';
import { useLocation } from 'react-router-dom';
import Page from '../components/layout/Page';
import PageContentWrapper from '../components/layout/PageContentWrapper';
import styled from 'styled-components';
import H1 from '../components/typography/H1';
import H2 from '../components/typography/H2';
import Breadcrumbs from '../components/Breadcrumbs';
import { useTranslation } from '../hooks/useTranslation';
import PDFList from 'src/components/PDFList';
import Divider from 'src/components/layout/Divider';
import { useManuals } from '../hooks/useSearch';

const MainContent = styled.div`
  width: 40%;
  padding-right: 10px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    width: 100%;
    padding-right: 0;
  }
`;

const StyledH1 = styled(H1)`
  text-transform: uppercase;
  margin-bottom: 48px;
`;

const StyledH2 = styled(H2)`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.m};
`;

const Downloads = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const path = pathname.split('/')[2];
  const { downloadManuals } = useManuals();
  return (
    <Page>
      <PageContentWrapper>
        <Breadcrumbs breadcrumbs={[{ label: path, url: `/${path}` }]} />
        <MainContent>
          <StyledH1>{t('downloads.downloadpdf')}</StyledH1>
          <StyledH2>{t('downloads.dowloads')}</StyledH2>
          <Divider />
          <PDFList items={downloadManuals} manualType={true} />
        </MainContent>
      </PageContentWrapper>
    </Page>
  );
};

export default Downloads;

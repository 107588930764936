import React, { RefObject, Dispatch, SetStateAction } from 'react';

export function useListFocus(
  size: number,
  wrapperRef: RefObject<HTMLDivElement>
): [number, Dispatch<SetStateAction<number>>] {
  const [currentFocus, setCurrentFocus] = React.useState<number>(-1);

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (currentFocus >= 0) {
          setCurrentFocus(currentFocus === 0 ? size - 1 : currentFocus - 1);
        }
      } else if (e.key !== 'Enter') {
        setCurrentFocus(-1);
      }
    },
    [size, currentFocus, setCurrentFocus]
  );

  React.useEffect(() => {
    const currentRef = wrapperRef.current;

    currentRef?.addEventListener('keydown', handleKeyDown, false);
    return () => {
      currentRef?.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown, wrapperRef]);

  return [currentFocus, setCurrentFocus];
}

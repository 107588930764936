import styled from 'styled-components';

export const PageContentRow = styled.div`
  display: flex;
  max-width: 1080px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    flex-direction: column;
  }
`;

import styled from 'styled-components';

export default styled.h1`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.xxl};
  line-height: ${({ theme }) => theme.lineHeights.l};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.esabTrueBlack};
`;

import React, { FC } from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.esabTrueBlack};

  &:hover {
    color: ${({ theme }) => theme.colors.esabHoverLinks};
  }

  &:active {
    color: ${({ theme }) => theme.colors.esabBlack20};
  }
`;

type AnchorProps = {
  href: string;
  children: React.ReactNode;
  data: string;
};

const Anchor: FC<AnchorProps> = ({ href, data }) => {
	const link = href && href.startsWith('S') ? `../${href}` : href;
  return <StyledLink href={link}>{data}</StyledLink>;
};

export default Anchor;

import * as React from 'react';
import parse, { domToReact } from 'html-react-parser';
import Anchor from './topic/Anchor';
import Div from './topic/Div';
import Img from './topic/Img';
import Table from './topic/Table';
import Paragraph from './topic/Paragraph';
import List from './topic/List';
import Span from './topic/Span';
import Hotspots from './hotspots/Hotspots';

type StringToComponentProps = {
  data: string;
};

const StringToComponent: React.FC<StringToComponentProps> = ({ data }) => {
  const [ReactComponent, setReactComponent] =
    React.useState<string | JSX.Element | JSX.Element[]>('');

  React.useEffect(() => {
    if (data) {
      const options = {
        replace: (node) => {
          if (node.attribs && node.attribs.class === 'hotspot-section') {
            return <></>;
          }
          switch (node.name) {
            case 'a': {
              const href = node.attribs.href.startsWith('www.')
                ? 'http://' + node.attribs.href
                : node.attribs.href;
              return (
                <Anchor href={href} data={node.children[0].data}>
                  {domToReact(node.children, options)}
                </Anchor>
              );
            }
            case 'div':
              if (node.attribs.class === 'imagemap') {
                const imgNode = node.children.find(
                  (child) => child.name === 'img'
                );
                const mapNode = node.children.find(
                  (child) => child.name === 'map'
                );
                const areas = mapNode
                  ? mapNode.children.filter((child) => child.name === 'area')
                  : [];
                const hotspotSections = node.parent.children.filter(
                  (child) =>
                    child.attribs && child.attribs.class === 'hotspot-section'
                );

                return (
                  <Hotspots
                    areas={areas}
                    hotspotSections={hotspotSections}
                    domToReact={domToReact}
                    options={options}
                  >
                    {domToReact([imgNode], options)}
                  </Hotspots>
                );
              }
              return (
                <Div node={node}>{domToReact(node.children, options)}</Div>
              );

            case 'img':
              return (
                <Img imageId={node.attribs['src']} node={node}>
                  {domToReact(node.children, options)}
                </Img>
              );

            case 'table': {
              return (
                <Table node={node}>{domToReact(node.children, options)}</Table>
              );
            }

            case 'p':
              return (
                <Paragraph>{domToReact(node.children, options)}</Paragraph>
              );

            case 'ul':
            case 'ol':
              return (
                <List node={node}>{domToReact(node.children, options)}</List>
              );

            case 'span':
              return (
                <Span className={node.attribs.class}>
                  {domToReact(node.children, options)}
                </Span>
              );

            default:
              return node;
          }
        },
      };
      setReactComponent(parse(data, options));
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return <>{ReactComponent}</>;
};

export default StringToComponent;

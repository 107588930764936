import * as React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './config/theme';
import Home from './pages/Home';
import Topic from './pages/Topic';
import Browse from './pages/Browse';
import BrowseSecondary from './pages/BrowseSecondary';
import ReactGA from 'react-ga';
import Downloads from './pages/Downloads';
import NotFound from './pages/NotFound';
import { useAuth, useSetConfig, useConfig } from './hooks/useAuth';
import { useProductSearch } from './hooks/useSearch';
import { constants } from './constants';

function App() {
  const config = useConfig();
  const authenticated = useAuth();
  const { mutate } = useSetConfig();
  ReactGA.initialize((config && config.configuration.ga_key) ?? '');
  const { data } = useProductSearch(
    '*',
    `manifestId eq '${window.location.href.split('/')[3]}'`
  );

  React.useEffect(() => {
    if (data && data.count) {
      document.title = `ESAB Manual: ${data.value[0].manualtitle ?? ''
        }`;
    }
  }, [data]);

  React.useEffect(() => {
    if (authenticated && !JSON.parse(sessionStorage.getItem(constants.configVersion))) {
      mutate(
        {},
        {
          onSuccess: (data) => {
            sessionStorage.setItem(constants.configVersion, JSON.stringify(data));
            window.location.reload();
          },
        }
      );
    }
  }, [authenticated, mutate]);

  if (!authenticated || !config) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path={['/', '/404']}>
            <NotFound />
          </Route>
          <Route
            exact
            path={['/:manifestId/parts', '/:manifestId/parts/:childId']}
          >
            <BrowseSecondary />
          </Route>
          <Route exact path="/:manifestId/downloads">
            <Downloads />
          </Route>
          <Route
            path={[
              '/:manifestId/topics/:topicId',
              '/:manifestId/:path/topics/:topicId',
            ]}
          >
            <Topic />
          </Route>
          <Route exact path="/:manifestId">
            <Home />
          </Route>
          <Route
            exact
            path={['/:manifestId/:path', '/:manifestId/:path/:childId']}
          >
            <Browse />
          </Route>
        </Switch>
      </Router>
      <div id="modal"></div>
    </ThemeProvider>
  );
}

export default App;

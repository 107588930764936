import React, { RefObject } from 'react';

export function useCloseClick(
  ref: RefObject<HTMLDivElement>,
  reset: string | boolean
): boolean {
  const [close, setClose] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (reset) {
      setClose(false);
    }
  }, [reset]);

  const keyboardListener = React.useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setClose(true);
    }
  }, []);

  const clickListener = React.useCallback(
    (e: MouseEvent) => {
      if (ref.current) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        if (!(ref.current! as any).contains(e.target)) {
          setClose(true);
        } else {
          setClose(false);
        }
      }
    },
    [ref]
  );

  React.useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', keyboardListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', keyboardListener);
    };
  }, [clickListener, keyboardListener]);

  return close;
}

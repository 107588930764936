import ReactGA from 'react-ga';

export function GAEvent(category: string, event: string, value: number): void {
  ReactGA.event({
    category: category,
    action: event,
    value: value,
    nonInteraction: false,
  });
}

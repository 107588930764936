import * as React from 'react';
import { getChildren } from '../helpers/node';
import ReactGA from 'react-ga';

type NodeProps = {
  node: Esab.Node | undefined;
  breadcrumbs?: Esab.BreadCrumb[];
};

export function useNodes(
  data: Esab.Node | undefined,
  childId: string,
  path: string
): NodeProps {
  const [node, setNode] = React.useState<NodeProps>({
    node: undefined,
    breadcrumbs: [],
  });

  React.useEffect(() => {
    const setBredcrumbs = (data) => {
      const pathBreadcrumb = { label: path, url: `/${path}` };

      if (childId) {
        const { nodeData, breadcrumbs } = getChildren(data, childId, path);
        setNode({
          node: nodeData,
          breadcrumbs: [pathBreadcrumb, ...breadcrumbs],
        });
      } else {
        setNode({ node: data, breadcrumbs: [pathBreadcrumb] });
      }
    };

    if (data !== undefined) {
      setBredcrumbs(data);
    }
  }, [data, childId, path]);

  //GOOGLE ANALYTICS STUFF ---------------------------------------------
  const topCrumb = node.breadcrumbs.slice(-1)[0];

  if (topCrumb !== undefined) {
    if (sessionStorage.getItem('ga-path') != window.location.pathname) {
      sessionStorage.setItem('ga-path', window.location.pathname);
      ReactGA.ga('set', 'title', topCrumb.label);
      ReactGA.ga('set', 'page', window.location.pathname);
      ReactGA.ga('send', 'pageview');
    }
  }
  //--------------------------------------------------------------------

  return { node: node.node, breadcrumbs: node.breadcrumbs };
}

import styled from 'styled-components';

export default styled.h2`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  line-height: ${({ theme }) => theme.lineHeights.m};
  color: ${({ theme }) => theme.colors.esabTrueBlack};
`;

import styled, { css } from 'styled-components';
import React from 'react';

const GuiStyle = css`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.esabBlack};
  @media print {
    line-height: initial;
  }
`;

const StyledSpan = styled.span<{
  gui?: boolean;
  term?: boolean;
  path?: boolean;
}>`
  font-family: ${({ theme }) => theme.fonts.regular};
  line-height: 0.14;

  ${({ gui }) => gui && GuiStyle};
`;

type SpanProps = {
  className: string;
};

const Span: React.FC<SpanProps> = ({ children, className }) => {
  if (!className) {
    return <StyledSpan>{children}</StyledSpan>;
  }

  return <StyledSpan className={className}>{children}</StyledSpan>;
};

export default Span;

import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import SearchResultItem from './SearchResultItem';

const ResultsDropdown = styled.ul`
  background-color: #ffffff;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0.641794px 1.28359px 5.13436px 1.28359px rgba(0, 0, 0, 0.25);
  border-radius: 3.85077px;
  max-height: 245px;
  overflow-x: hidden;
  overflow-y: auto;
  top: 55px;
  border-radius: 3.85077px;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9;
`;

type SearchResultsProps = {
  data: Esab.SearchResultDocument[];
  showResultsDropdown: boolean;
  currentFocus: number;
  setCurrentFocus: Dispatch<SetStateAction<number>>;
  inHeader?: boolean;
};

const SearchResults: React.FC<SearchResultsProps> = ({
  data,
  showResultsDropdown,
  currentFocus,
  setCurrentFocus,
  inHeader,
}) => (
  <>
    {showResultsDropdown && data.length > 0 && (
      <ResultsDropdown>
        {data.map((result, index) => {
          return (
            <SearchResultItem
              key={index}
              data={result}
              focus={currentFocus === index}
              setFocus={setCurrentFocus}
              index={index}
              inHeader={inHeader}
            />
          );
        })}
      </ResultsDropdown>
    )}
  </>
);

export default SearchResults;

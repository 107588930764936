import * as React from 'react';
import Modal from './Modal';
import H2 from '../typography/H2';
import styled from 'styled-components';
import Button from '../Button';
import { useTranslation } from '../../hooks/useTranslation';

const Wrapper = styled.div`
  padding: 0 16px 16px;
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.esabBlack};
  padding: 30px 0 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type FeedbackModalProps = {
  isVisible: boolean;
  closeHandler: () => void;
};

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isVisible,
  closeHandler,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isVisible={isVisible} onClose={closeHandler}>
      <Wrapper>
        <H2>{t('feedback.modalTitle')}</H2>
        <Text>{t('feedback.modalText')}</Text>
        <ModalFooter>
          <Button text={t('feedback.buttonText')} onClick={closeHandler} />
        </ModalFooter>
      </Wrapper>
    </Modal>
  );
};

export default FeedbackModal;

import * as React from 'react';
import styled from 'styled-components';
import H3 from './typography/H3';
import { types } from '../../src/mappings';
import { useLocation } from 'react-router-dom';

const IconWrapper = styled.div`
  padding: 0 10px;
`;

const PDFIcon = styled.img`
  max-width: initial;
  margin-top: -5px;
`;

const Wrapper = styled.div`
  padding: 20px 0 40px 0;
`;

const Item = styled.div``;

const Link = styled.a`
  display: flex;
  padding: 8px 0;
`;

const Information = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.esabBlack80};
  line-height: 13.8px;
`;

const StyledH3 = styled(H3)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  color: ${({ theme }) => theme.colors.esabTrueBlack};
  margin-top: -5px;
  margin-bottom: 5px;
`;

const Details = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.esabBlack80};
  span.bold {
    font-weight: bold;
  }
`;

const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.esabTrueBlack};
`;

type PDFListProps = {
  items?: Esab.ProductSearchResults;
  manualType?: boolean;
};

const InfoData: React.FC<{ manual: Esab.ProductDocument | any }> = ({
  manual,
}) => {
  const [infoData, setinfoData] = React.useState<string[]>([]);

  React.useEffect(() => {
    const infoKeysToString = {
      Documentno: 'Document No',
      Releasedate: 'Release date',
      Productgin: 'Article No',
    };
    const info: string[] = [];

    for (const key in manual.document) {
      if (Object.keys(infoKeysToString).includes(key) && manual.document[key]) {
        const value = Array.isArray(manual.document[key])
          ? manual.document[key].join(', ')
          : manual.document[key];
        info.push(`${infoKeysToString[key]}: ${value}`);
      }
    }

    setinfoData(info);
  }, [manual]);

  if (!infoData.length) {
    return null;
  }

  return (
    <Information>
      {infoData.map((info, index) => (
        <span key={index}>
          {info} {infoData.length !== index + 1 && '-'}{' '}
        </span>
      ))}
    </Information>
  );
};

const PDFList: React.FC<PDFListProps> = ({ items, manualType }) => {
  const location = useLocation();
  const lang = location.pathname.split('/')[1].split('_')[
    location.pathname.split('/')[1].split('_').length - 1
  ];

  if (!items || !items.value) {
    return null;
  }
  const getTypeName = (type: string) => {
    return types.filter((x) => x.key === type)[0].value;
  };
  const getDetails = (manual: Esab.ProductDocument) => {
    const parts = [];
    if (manual.documentno) {
      parts.push(`Document number: ${manual.documentno}`);
    }
    if (manual.releasedate) {
      parts.push(`Release date: ${manual.releasedate}`);
    }
    if (manual.serialNo) {
      parts.push(`<span class="bold">Serial number: ${manual.serialNo}</span>`);
    }
    return parts.join(' - ');
  };

  return (
    <Wrapper>
      {items.value.length > 0 &&
        items.value.map((manual, index) => {
          if (
            manual.pdflanguage.includes(lang) ||
            (manual.pdflanguage.includes('en-GB') &&
              items.value.filter(
                (i) =>
                  i.pdflanguage.includes(lang) &&
                  i.documentno === manual.documentno
              ).length === 0)
          ) {
            return (
              <Item key={index}>
                <Link
                  href={manual.filelink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWrapper>
                    <PDFIcon src="/images/pdf.svg" />
                  </IconWrapper>
                  <div>
                    <StyledH3>
                      {manualType
                        ? getTypeName(manual.manualtype[0])
                        : manual.manualtitle}
                    </StyledH3>
                    {!manualType && (
                      <Subtitle>{getTypeName(manual.manualtype[0])}</Subtitle>
                    )}
                    <Details
                      dangerouslySetInnerHTML={{ __html: getDetails(manual) }}
                    />
                    <InfoData manual={manual} />
                  </div>
                </Link>
              </Item>
            );
          } else {
            return null;
          }
        })}
    </Wrapper>
  );
};

export default PDFList;

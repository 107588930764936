import * as React from 'react';
import styled, { css } from 'styled-components';

const InactiveStyle = css`
  background-color: ${({ theme }) => theme.colors.esabBlack10};
  color: ${({ theme }) => theme.colors.esabBlack30};
  pointer-events: none;
`;

const ButtonWrapper = styled.button<{ inactive: boolean; hasIcon: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ hasIcon }) => (hasIcon ? 'center' : 'center')};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 18px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 10px;
  background: #ffffff;
  color: ${({ theme }) => theme.colors.esabBlack};

  &:hover {
    background: ${({ theme }) => theme.colors.esabBlack10};
  }

  &:active {
    background: ${({ theme }) => theme.colors.esabBlack44};
    color: ${({ theme }) => theme.colors.esabBlack10};
  }

  ${({ inactive }) => inactive && InactiveStyle};
`;

type ButtonProps = {
  text: string;
  icon?: React.ReactChild;
  onClick: () => void;
};

const Button: React.FC<ButtonProps> = ({ text, icon, onClick }) => {
  return (
    <ButtonWrapper
      inactive={false}
      hasIcon={icon ? true : false}
      onClick={onClick}
    >
      {icon && icon}
      {text}
    </ButtonWrapper>
  );
};

export default Button;
